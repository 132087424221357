exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-3-post-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/blog/3-post.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-3-post-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-my-post-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/blog/my=post.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-my-post-mdx" */)
}

